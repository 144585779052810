import React, { useContext, useEffect, useState } from 'react'
import { Button, Input } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../../taskpane/@common/contexts/auth';

const Login = () => {
    const auth = useContext(AuthContext)

    const [workspaceUrl, setWorkspaceUrl] = useState<string>()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();


    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            navigate('/home')
        }

        if (searchParams.get('returnUrl')) {
            Office.context.ui.messageParent(searchParams.get('returnUrl'))
        }

    }, [])


    const openURL = () => {
        const _rawUrl = new URL(window.location.href)
        const url = `https://arena.${workspaceUrl}.inncircles.com//#/?action=outlookDialog&redirectUrl=${_rawUrl.origin}`
        Office.context.ui.displayDialogAsync(url, { height: 50, width: 50 },
            function (asyncResult) {
                const dialog = asyncResult.value;

                dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
                    dialog.close()
                    if (!arg.error) {
                        auth.saveWorkspaceUrl(workspaceUrl)
                        auth.login(arg.message)
                        navigate('/home')
                    }
                });
            }
        );

    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>

            <img style={{ width: '28rem' }} src={'../../../../assets/landing.png'} />

            <div style={{
                padding: 30, marginInline: 20, display: 'flex', flexDirection: 'column',
                alignItems: 'center', border: '1px solid #EBEBED', borderRadius: 8, boxShadow: '0 4px 12px #FAB13344'
            }}>
                <img style={{ width: '8rem' }} src={'../../../../assets/landing-icon.png'} />
                <h2 style={{ marginTop: 16, marginBottom: 10, fontWeight: 500 }}>Welcome Back!</h2>

                <div style={{ marginTop: 10 }}>
                    <span style={{ fontSize: 11, fontWeight: 500 }}>Enter Workspace</span>
                    <Input prefix="arena." suffix=".inncircles.com" style={{ marginBlock: 4 }} onChange={e => setWorkspaceUrl(e.target.value)} />
                    <Button style={{ backgroundColor: '#FAB133', width: '100%', marginTop: 4 }} onClick={openURL}>Sign In</Button>
                </div>
            </div>
        </div>
    )
}

export default Login
