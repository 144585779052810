export enum CommunicationFeatureType {
    FABRICATION = "FABRICATION",
    WORK_ORDER = "WORK_ORDER",
    CHANGE_ORDER = "CHANGE_ORDER",
    SERVICE_REQUEST = "SERVICE_REQUEST",
    LOR = "LOR",
    LEAD_MANAGEMENT = "LEAD_MANAGEMENT",
    WORK_ORDER_CONTRACT = "WORK_ORDER_CONTRACT",
    REQ = "REQ",
    VENDOR_REQ = 'VENDOR_REQ',
    RFQ = "RFQ",
    VENDOR_RFQ = 'VENDOR_RFQ',
    PURCHASE_ORDER = 'PURCHASE_ORDER',
    DELIVERY_RECEIPTS = 'DELIVERY_RECEIPTS',
    INVOICE = 'INVOICE',
    PICKUP_REQUEST = 'PICKUP_REQUEST',
    EXPENSE_TRACKER_FORM = 'EXPENSE_TRACKER_FORM',
    PROCUREMENT = 'PROCUREMENT',
    PROPOSAL_MANAGEMENT = 'PROPOSAL_MANAGEMENT',
    BID_MANAGEMENT_US = 'BID_MANAGEMENT_US',
    ARENA_COMMUNICATIONS = 'ARENA_COMMUNICATIONS'
}

export enum linkingModule {
    LEAD_MANAGEMENT = "LEAD_MANAGEMENT",
    PROPOSAL_MANAGEMENT = "PROPOSAL_MANAGEMENT",
    BID_MANAGEMENT_US = "BID_MANAGEMENT_US"
}