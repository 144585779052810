import React, { useEffect, useMemo, useState } from "react";
import { AuthContext } from "../contexts/auth";
import { jwtDecode } from "jwt-decode";
import { NamingFrameworkContext } from "../contexts/naming-framework";
import { NamingFrameWork } from "src/models/model";
import { namingFrameWorkNames } from "../../../config/constants";
import axios from "axios";



export function AuthProvider(props) {
    const [user, setUser] = useState(null)
    const [workspaceUrl, setWorkspaceUrl] = useState(null)
    const [namingFramework, setNamingFramework] = useState<NamingFrameWork>(null);

    const setNamingFrameWorkData = () => {
        const _workspaceUrl = localStorage.getItem('workspaceUrl')
        const namingFramework: NamingFrameWork = namingFrameWorkNames;
        axios.get(`https://code.arena.${_workspaceUrl}.inncircles.com/naming-framework/names`, null).then((res: any) => {
            setNamingFramework({ ...res.data.data });
        }).catch(() => {
            setNamingFramework(namingFramework);
        })
    }

    useEffect(() => {
        const _workspaceUrl = localStorage.getItem('workspaceUrl')
        if (_workspaceUrl) {
            setWorkspaceUrl(_workspaceUrl)
            setNamingFrameWorkData();
        }
    }, [])

    useEffect(() => {
        const _user = localStorage.getItem('user')
        if (_user) {
            setUser(JSON.parse(_user))
        }
    }, [])


    function login(token: string) {
        localStorage.setItem('token', token)
        const decodedUser = jwtDecode(token)
        localStorage.setItem('user', JSON.stringify(decodedUser))
        setUser(decodedUser)
    }

    function logout() {
        setUser(null)
        localStorage.clear()
    }

    function saveWorkspaceUrl(url: string) {
        localStorage.setItem('workspaceUrl', url)
        setWorkspaceUrl(url)
    }

    const authContext = {
        user, logout, login, workspaceUrl, saveWorkspaceUrl
    };

    const namingFrameworkContext = {
        namingFramework, setNamingFrameWorkData
    };

    return (
        <AuthContext.Provider value={authContext}>
            <NamingFrameworkContext.Provider value={namingFrameworkContext}>
                {props.children}
            </NamingFrameworkContext.Provider>
        </AuthContext.Provider>
    )
}