import { Button, Flex, Select } from 'antd'
import axios, { AxiosRequestConfig } from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../taskpane/@common/contexts/auth'
import { RightOutlined, CheckOutlined } from '@ant-design/icons';
import { CommunicationFeatureType, linkingModule } from '../../../taskpane/@common/types/enums';
import { retrieveDisplayNameForUser } from '../../../taskpane/@common/utils/helper';
import { NamingFrameworkContext } from '../../../taskpane/@common/contexts/naming-framework';
import { namingFrameWorkNames } from '../../../config/constants';

export enum EmailLabelType {
    DRAFT = 'DRAFT',
    INBOX = 'INBOX',
    SENT = 'SENT',
    UNREAD = 'UNREAD',
    TRASH = 'TRASH',
    STARRED = 'STARRED',
}

const Home = () => {
    const auth = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [messageSent, setMessageSent] = useState(false)
    const namingFramework = useContext(NamingFrameworkContext)
    const features = [
        { value: CommunicationFeatureType.LEAD_MANAGEMENT, label: <span>{namingFramework.namingFramework?.['lead'] ?? namingFrameWorkNames['lead']} Management</span> },
        { value: CommunicationFeatureType.PROPOSAL_MANAGEMENT, label: <span>Proposal Management</span> }
    ]
    const [proposalTypes, setProposalTypes] = useState([])
    const [selectedFeature, setSelectedFeature] = useState(CommunicationFeatureType.LEAD_MANAGEMENT)
    const [selectedProposalTypes, setSelectedProposalTypes] = useState([])
    const [selectedProposals, setSelectedProposals] = useState([])
    const [proposals, setProposals] = useState([])

    const sendData = async () => {
        try {
            setLoading(true)
            const body = Office.context.mailbox.item.body
            const bcc = Office.context.mailbox.item.bcc
            const attachments = Office.context.mailbox.item.attachments
            let module = linkingModule.PROPOSAL_MANAGEMENT
            switch (selectedFeature) {
                case CommunicationFeatureType.PROPOSAL_MANAGEMENT:
                    module = linkingModule.PROPOSAL_MANAGEMENT
                    break;
                case CommunicationFeatureType.BID_MANAGEMENT_US:
                    module = linkingModule.BID_MANAGEMENT_US
                    break;
                case CommunicationFeatureType.LEAD_MANAGEMENT:
                    module = linkingModule.LEAD_MANAGEMENT
                    break;
                default:
                    module = linkingModule.LEAD_MANAGEMENT
            }
            const item = Office.context.mailbox.item;
            const labels = [];
            const currentUser = Office.context.mailbox.userProfile.emailAddress;

            if (item.to && Array.isArray(item.to)) {
                const recipients = item.to.map(each => each.emailAddress);

                if (recipients.includes(currentUser)) {
                    labels.push("INBOX")
                } else if (item.sender && item.sender.emailAddress === currentUser) {
                    labels.push("SENT")
                }
            }

            let payload: any = {
                subject: Office.context.mailbox.item.subject,
                sender: Office.context.mailbox.item.sender.emailAddress,
                receivers: Office.context.mailbox.item.to.map(each => each.emailAddress),
                cc: Office.context.mailbox.item.cc.map(each => each.emailAddress),
                emailMessageId: Office.context.mailbox.item.itemId,
                emailThreadId: Office.context.mailbox.item.conversationId,
                labels: labels,
                module: module,
                customer: selectedCompanies,
                type: selectedFeature,
                opportunity: selectedOppportunities,
                poc: selectedPOCs,
                proposalTypes: selectedProposalTypes,
                proposals: selectedProposals
            }


            await new Promise((res) => {
                body.getAsync(Office.CoercionType.Html, function (asyncResult) {
                    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        payload.message = asyncResult.value
                        res(true)
                    }
                })
            })

            if (attachments?.length) {
                const _attachmentsPayload = []
                for (const attachment of attachments) {
                    await new Promise((res) => {
                        Office.context.mailbox.item.getAttachmentContentAsync(attachment.id, {}, (asyncResult) => {
                            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                                _attachmentsPayload.push({
                                    ...attachment,
                                    value: asyncResult.value.content
                                })
                                res(true)
                            }
                        })
                    })
                }

                payload._attachments = _attachmentsPayload
            }



            // bcc.getAsync({}, (asyncResult) => {
            //     if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            //         data.bcc = asyncResult.value
            //     }
            // })
            await axios.post(`https://code.arena.${auth.workspaceUrl}.inncircles.com/mail-communication/outlook-add-in`, payload)
            setLoading(false)
            setMessageSent(true)
            // Disappear the message after 5 seconds
            setTimeout(() => {
                setMessageSent(false)
            }, 5000)
        } catch (err) {
            setLoading(false)
        }
    }


    useEffect(() => {
        fetchGroups()
        onGroupChange([])
    }, [])

    const [groups, setGroups] = useState([])
    const fetchGroups = async () => {
        const res = await axios.get(`https://code.arena.${auth.workspaceUrl}.inncircles.com/company-group/list`)
        if (res.data.success) {
            setGroups(res.data.data?.map(each => ({ value: each._id, label: each.name })))
        }
    }

    const [company, setCompany] = useState()
    const onGroupChange = async (value: string[]) => {
        const _request: AxiosRequestConfig = {
            method: 'POST',
            url: `https://code.arena.${auth.workspaceUrl}.inncircles.com/company/list/all`,
            data: {
                filters: { groups: value }
            }
        }
        const res = await axios(_request)

        if (res.data.success) {
            setCompany(res.data.data?.map(each => ({ value: each._id, label: each.companyName })))
            let companies = []
            for (let i = 0; i < selectedCompanies.length; i++) {
                res.data.data.forEach((each) => {
                    if (each._id == selectedCompanies[i]) {
                        companies.push(selectedCompanies[i])
                    }
                })
            }
            setSelectedCompanies(companies)
        }
    }

    const [selectedCompanies, setSelectedCompanies] = useState([])
    const onCustomerSelected = (value: string[]) => {
        setSelectedCompanies(value)
        getPOCWRTCompanies(value)
    }

    const [pocs, setPocs] = useState()
    const getPOCWRTCompanies = async (selectedCompanies?: string[]) => {
        const res = await axios.post(`https://code.arena.${auth.workspaceUrl}.inncircles.com/company/list/pocs`, {
            companies: selectedCompanies
        })
        if (res.data.success) {
            setPocs(res.data.data?.map(each => ({ value: each._id, label: retrieveDisplayNameForUser(each) })))
            let pocs = []
            for (let i = 0; i < selectedPOCs.length; i++) {
                res.data.data.forEach((each) => {
                    if (each._id == selectedPOCs[i]) {
                        pocs.push(selectedPOCs[i])
                    }
                })
            }
            setSelectedPOCs(pocs)
        }
    }

    useEffect(() => {
        if (selectedPOCs.length) {
            onPOCSelected(selectedPOCs)
        } else {
            setSelectedOppportunities([])
        }
    }, [pocs])

    useEffect(() => {
        if (selectedCompanies.length) {
            getPOCWRTCompanies(selectedCompanies)
        } else {
            setSelectedPOCs([])
        }
    }, [selectedCompanies])

    const [selectedPOCs, setSelectedPOCs] = useState([])
    const [opportunities, setOpportunities] = useState()
    const onPOCSelected = async (value: string[]) => {
        setSelectedPOCs(value)
        const res = await axios.post(`https://code.arena.${auth.workspaceUrl}.inncircles.com/lead/leads/pocs`, {
            pocs: value,
            leads: []
        })
        if (res.data.success) {
            setOpportunities(res.data.data?.linkedLeads?.map(each => ({ value: each._id, label: each.name })))
            let opportunities = []
            for (let i = 0; i < selectedOppportunities.length; i++) {
                res.data.data.linkedLeads.forEach((each) => {
                    if (each._id == selectedOppportunities[i]) {
                        opportunities.push(selectedOppportunities[i])
                    }
                })
            }
            setSelectedOppportunities(opportunities)
        }
    }

    const [selectedOppportunities, setSelectedOppportunities] = useState([])
    const onOpportunitySelected = async (value: string[]) => {
        setSelectedOppportunities(value)
    }

    const fetchProposalTypes = async () => {
        const res = await axios.get(`https://code.arena.${auth.workspaceUrl}.inncircles.com/proposal/proposal-types`)
        if (res.data.success) {
            setProposalTypes(res.data.data?.map(each => ({ value: each._id, label: each.name })))
        }
    }


    const onFeatureSelected = (value: CommunicationFeatureType) => {
        setSelectedFeature(value)
        if (value == CommunicationFeatureType.PROPOSAL_MANAGEMENT) {
            setSelectedProposals([]);
            setSelectedProposalTypes([]);
            fetchProposalTypes()
        } else if (value == CommunicationFeatureType.LEAD_MANAGEMENT) {
            setSelectedCompanies([]);
            setSelectedPOCs([]);
            setSelectedOppportunities([]);
        }
    }

    const onProposalTypesSelected = async (value: string[]) => {
        setSelectedProposalTypes(value)
        const res = await axios.post(`https://code.arena.${auth.workspaceUrl}.inncircles.com/proposal/proposal-based-on-types`, value)
        if (res.data.success) {
            setProposals(res.data.data?.map(each => ({ value: each._id, label: each.name })))
            let proposals = []
            for (let i = 0; i < selectedProposals.length; i++) {
                res.data.data.forEach((each) => {
                    if (each._id == selectedProposals[i]) {
                        proposals.push(selectedProposals[i])
                    }
                })
            }
            setSelectedProposals(proposals)
        }
    }

    const onProposalsSelected = (value: string[]) => {
        setSelectedProposals(value)
    }


    return (
        <>
            <Flex align='center' justify='center' style={{ flexDirection: 'column', width: '100%', height: '90vh' }}>
                <h2 style={{ marginBottom: 12, fontWeight: 600 }}>Select an Action</h2>
                <Flex style={{ flexDirection: 'column', width: '80%', gap: '1rem', marginBottom: '1rem' }}>
                    <Select defaultValue={CommunicationFeatureType.LEAD_MANAGEMENT} value={selectedFeature}
                        options={features} onChange={onFeatureSelected} />
                    {
                        selectedFeature === CommunicationFeatureType.LEAD_MANAGEMENT &&
                        <>
                            <Select options={groups} mode="multiple" allowClear onChange={onGroupChange} placeholder="Groups" />
                            <Select options={company} mode="multiple" allowClear value={selectedCompanies} onChange={onCustomerSelected} placeholder="Customer" />
                            <Select options={pocs} mode="multiple" allowClear value={selectedPOCs} onChange={onPOCSelected} placeholder="Customer POC" />
                            <Select options={opportunities} mode="multiple" allowClear value={selectedOppportunities} onChange={onOpportunitySelected} placeholder="Opportunity" />
                        </>
                    }
                    {
                        selectedFeature === CommunicationFeatureType.PROPOSAL_MANAGEMENT &&
                        <>
                            <Select options={proposalTypes} mode="multiple" allowClear value={selectedProposalTypes} onChange={onProposalTypesSelected} placeholder="Proposal type" />
                            <Select options={proposals} mode="multiple" allowClear value={selectedProposals} onChange={onProposalsSelected} placeholder="Proposals" />
                        </>
                    }
                </Flex>
                <Button style={{ marginBottom: 12, display: 'flex', alignItems: 'center', gap: 2 }}
                    loading={loading} type="primary" shape="round" onClick={sendData}>
                    Send Mail to Arena <RightOutlined size={18} />
                </Button>

                {/* <Button onClick={fetchCustomers}>Fetch Data</Button> */}

                {
                    messageSent &&
                    <span>Mail Sent Successfully <CheckOutlined color='green' /></span>
                }
            </Flex>
        </>
    )
}

export default Home
