import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Login from './(auth)/Login';
import HomeLayout from './(home)/HomeLayout';
import { AuthProvider } from '../@common/providers/auth.provider';
import Home from './(home)/Home';
import Layout from './(auth)/Layout';
import '../@common/services/request.service';


const router = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [{
      path: "",
      element: <Login />,
    }],
  }, {
    path: "home",
    element: <HomeLayout />,
    children: [{
      path: "",
      element: <Home />
    }]
  }, {
    path: '*',
    element: <h1>Page Not Found</h1>
  }
]);


const Main = () => {

  return (

    <AuthProvider>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </AuthProvider>
  )
}

export default Main
