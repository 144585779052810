import axios from "axios";
let isInterceptorSetup = false;

export const setupAxiosInterceptors = (authContext, navigate) => {
    if (isInterceptorSetup) return;

    axios.interceptors.request.use(
        function (config) {
            const token = localStorage.getItem('token')
            config.headers.Authorization = `Bearer ${token}`;


            return config

        }, function (error) {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        function (response) {
            if (response.data.code === 1001) {
                authContext.logout();
                navigate('/')
                return
            }
            return response;
        }
    );

    isInterceptorSetup = true;
};