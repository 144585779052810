import { createRoot } from 'react-dom/client'
import React from 'react';
import Main from './routes/main';


const rootElement: HTMLElement = document.getElementById('container')
const root = createRoot(rootElement)

/* Render application after Office initializes */
Office.onReady(() => {
    root.render(
        <Main />
    );
});


// if ((module as any).hot) {
//     (module as any).hot.accept("./routes/main", () => {
//         const NextApp = require("./routes/main").default;
//         root.render(NextApp);
//     });
// }
