import { NamingFrameWork } from "src/models/model";
export const namingFrameWorkNames: NamingFrameWork = {
    entity: "entity",
    superLocation: "superlocation",
    location: "location",
    activity: "activity",
    workPackage: "workpackage",
    subActivity: "subactivity",
    dailySafetyIssue: "dailySafetyIssue",
    dsi: "dsi",
    punchList: "punchlist",
    pnl: "pnl",
    observation: "observation",
    obs: "obs",
    restraint: "restraint",
    res: "res",
    qualityWorkFlowIssue: "Quality Workflow Issue",
    safetyWorkFlowIssue: "Safety Workflow Issue",
    qualityApproval: "Quality Approval",
    safetyApproval: "Safety Approval",
    formWorkflowIssue: "Form Workflow Issues",
    loopFolder: "Loop Folder",
    drawingPackage: "Drawing Package",
    drawingWorkFlowIssue: "Drawing Workflow Issue",
    inventorymaster: "Inventory Master",
    pickupticket: "Pickup Ticket",
    shipticket: "Ship Ticket",
    returnticket: "Return Ticket",
    project: "Project",
    loadOutRequest: 'Loadout Request',
    lor: 'LOR',
    request: 'Request',
    checkOut: 'Check Out',
    checkIn: 'Check In',
    shopIn: 'Shop In',
    lorShip: 'LOR Ship',
    equipmentIssues: 'Equipment Issues',
    dei: 'DEI',
    nonConformanceReport: 'Non Conformance Report',
    ncr: 'NCR',
    //** AWP Naming framework */
    cwa: "CWA",
    cwp: "CWP",
    ewp: "EWP",
    iwp: "IWP",
    tag: "Tag",
    pickup: "pickup",
    ship: "ship",
    'return': "return",
    version: "Version", // TODO remove it
    treeVersion: "Tree Version",
    qualityanddocuments: "Quality and Documents",
    qualityWFI: "Q-WFI",
    quality: "Quality",
    safetyWFI: "S-WFI",
    quantityWFI: "Quantity WorkFlow Issue",
    roleWFI: "Timesheet Issue",
    formWFI: "F-WFI",
    procurementPackage: "Procurement Package",
    rfq: "RFQ",
    rfqShort: "RFQ",
    vendor: "Vendor",
    purchaseOrder: "Purchase Order",
    materialReceipt: "Material Receipt",
    pp: "PP",
    po: "PO",
    mr: 'MR',
    workorder: 'Workorder',
    safety: 'Safety',
    shipment: 'Shipment',
    load: 'Load',
    inTransit: 'In Transit',
    delivered: 'Delivered',
    received: 'Received',
    task: 'Task',
    lead: 'Lead',
    proposal: 'Proposal'
}