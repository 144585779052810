import { createContext } from "react";

interface AuthContextType {
    user: {
        id: string
        fullName: string
        lastName: string
        username: string
    }
    login: (token: string) => void
    logout: () => void
    saveWorkspaceUrl: (url: string) => void
    workspaceUrl: string | null
}

export const AuthContext = createContext<AuthContextType>(null);